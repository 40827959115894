<template>
  <div>
    <template v-if="layout == 'default'">
      <Container class="default-text-wrap">
        <Headline class="headline" alignment="center">
          {{ title }}
        </Headline>
      </Container>
      <div v-if="videoSrc" class="default-video-wrap">
        <PlayButton
          v-if="hasControls"
          class="masthead-video-play-button"
          :is-playing="isPlaying"
          @click="playButtonPush"
          size="small"
        />
        <video
          ref="video"
          :src="videoSrc"
          :muted="muted"
          :autoplay="autoplay"
          :loop="loop"
          preload="auto"
          class="video"
          playsinline
        />
      </div>
      <Container class="default-text-wrap-small">
        <p class="default-page-header-description">
          {{ description }}
        </p>
      </Container>
    </template>
    <template v-if="layout == 'small'">
      <div class="small-wrap">
        <div v-show="whenToShowContent" class="small-content-wrap">
          <Container class="small-content">
            <Headline class="headline" alignment="center">
              {{ title }}
            </Headline>
            <p class="small-description">
              {{ description }}
            </p>
          </Container>
        </div>
        <template v-if="videoSrc">
          <video
            ref="video"
            :src="videoSrc"
            :muted="muted"
            :autoplay="autoplay"
            :loop="loop"
            preload="auto"
            class="video"
            playsinline
          />
          <PlayButton
            v-if="hasControls"
            class="masthead-video-play-button"
            :is-playing="isPlaying"
            @click="playButtonPush"
            size="small"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import PlayButton from "~/components/PlayButton"
  import Headline from "~/components/Headline"

  export default {
    components: {
      PlayButton,
      Headline,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      muted: {
        type: Boolean,
        default: true,
      },
      autoplay: {
        type: Boolean,
        default: false,
      },
      description: {
        type: String,
        required: true,
      },
      videoSrc: {
        type: String,
        default: null,
      },
      hasControls: {
        type: Boolean,
        default: false,
      },
      loop: {
        type: Boolean,
        default: true,
      },
      layout: {
        type: String,
        validator(value) {
          return ["default", "small"].includes(value)
        },
        default: "default",
      },
    },
    data() {
      return {
        isPlaying: this.autoplay,
      }
    },
    computed: {
      whenToShowContent() {
        if (this.hasControls && this.isPlaying) {
          return false
        }
        if (this.hasControls && !this.isPlaying) {
          return true
        }
        if (!this.hasControls) {
          return true
        }
        return true
      },
    },
    methods: {
      playButtonPush() {
        if (this.isPlaying) {
          this.isPlaying = false
          this.$refs.video.pause()
        } else {
          this.isPlaying = true
          this.$refs.video.play()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .headline {
    margin: 0;
  }
  .default-page-header-description {
    @apply h3;
  }

  .default-video-wrap {
    position: relative;
    height: 65vh;
    width: 100%;
    background-position: center center;
    background-size: cover;
    margin-bottom: $gutter;
  }

  .video {
    height: inherit;
    width: 100%;
    object-fit: cover;
  }

  .masthead-video-play-button {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 6.25%;
    z-index: 500;
  }
  /* Small */

  .small-wrap {
    color: white;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;
    height: 480px;
    background-position: center center;
    background-size: cover;
  }

  .small-thumb {
    height: 100%;
    width: 100%;
  }

  .small-content-wrap {
    padding-top: $base-spacing * 20;
    position: absolute;
    width: 100%;
    z-index: 5;
    background: rgba(0, 0, 0, 0.2);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .small-description {
    @apply text-lg leading-normal;
    margin: 0;
  }
</style>
