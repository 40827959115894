<template>
  <MastHeadVideo
    :title="title"
    :description="description"
    :video-src="videoSrc"
    :muted="muted"
    :has-controls="showControls"
    :loop="loop"
    :layout="layout"
    :autoplay="autoplay"
  />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      title() {
        return this.getValue("title")
      },
      description() {
        return this.getValue("description")
      },
      videoSrc() {
        return this.getValue("video")
      },
      autoplay() {
        return this.getValue("autoplay")
      },
      muted() {
        return this.getValue("muted")
      },
      showControls() {
        return this.getValue("show_controls")
      },
      loop() {
        return this.getValue("loop")
      },
      layout() {
        return this.getValue("layout")
      },
    },
  }
</script>
